<template>
    <div class="help">
        <div class="help__wrapper">
            <h1 class="help__title">
                Мы помогаем получить деньги
            </h1>
            <ul class="help__list">
                <li>
                    <p class="help__list-title">
                        19 542
                    </p>
                    <p class="help__list-desc">
                        одобренных займов
                    </p>
                </li>
                <li>
                    <p class="help__list-title">
                        683,97M
                    </p>
                    <p class="help__list-desc">
                        рублей выдано
                    </p>
                </li>
                <li>
                    <p class="help__list-title">
                        98%
                    </p>
                    <p class="help__list-desc">
                        вероятность одобрения
                    </p>
                </li>
            </ul>
            <div class="help__block">
                <p>
                    Поможем вам получить деньги в течение <strong>10 минут</strong>
                </p>
                <router-link class="help__action" :to="{ name: 'ContactInfo' }">
                    Получить
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import './help.scss'

export default {
    name: 'Help'
}
</script>